.orders {
  background-color: #eeeeee !important;
  overflow-y: scroll;
  height: 100vh;
}
.orders::-webkit-scrollbar {
  display: none;
}
.orders__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
}
.orders__header > h2 {
  font-family: "MuseoSans";
}
.orders__top {
  background-color: white;
  padding: 20px;
}
.orders__top > p {
  color: gray;
  margin-top: 10px;
  font-weight: 400 !important;
  font-family: "MuseoSans";
}

.orders__container {
  
  margin-top: 20px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  margin-bottom: 20px !important;
}
.orders__containerHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orders__containerHeading > img {
  margin-bottom: 15px;
}
.orders__containerHeading > h2 {
  font-size: 16px !important;
  font-family: "MuseoSans";
}
.orders__containerHeading > p {
  color: gray;
  font-size: 14px;
  font-family: "MuseoSans";
}
.orders__containerPara > p {
  color: gray;
  font-size: 14px;
  font-family: "MuseoSans";
  margin-top: 10px;
}
.orders__containerPara > h5 {
  margin-top: 10px;
  font-family: "MuseoSans";
  color: gray;
  margin-top: 20px;
}

.orders > Button {
  z-index: 1;
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  font-family: "MuseoSans";
}

.orders .MuiButton-root {
  font-size: 16px !important;
  border-radius: 0 !important;
  
}
