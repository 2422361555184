.failed {
  background-color: white;
  height: 100vh;
}
.failed__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  height: 45px;
}
.failed__header > h2 {
  font-family: "MuseoSans";
}
.failed__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
}
.failed__image {
  height: 80px;
}
.failed__body > p {
  font-family: "MuseoSans";
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
}
.failed__body > img {
  object-fit: contain;
  height: 48px;
  margin-top: 20px;
}
.failed__body > Button {
  text-transform: inherit !important;
  font-family: "MuseoSans";
  width: 60%;
  margin-top: 20px;
  text-transform: inherit !important;
}
