html {
  overflow-x: hidden !important;
}
* {
  margin: 0;
}
.App {
  background-color: #eeeeee;
  height: 100vh;
}
.App__arabic {
  direction: rtl;
}
