.processing {
  background-color: white;
  height: 100vh;
}
.processing__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  height: 45px;
}
.processing__header > h2 {
  font-family: "MuseoSans";
}
.processing__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
}
.processing__body > img {
  margin-bottom: 10px;
}
.processing__body > p {
  margin-top: 10px;
  font-family: "MuseoSans";
}
.loadingImg {
  height: 100px !important;
}
