.mobilePage {
  background-color: #eeeeee;
  height: 100vh;
}
.mobilepage__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  height: 90px;
}
.mobilepage__header > h2 {
  font-family: "MuseoSans";
}
.mobilepage__header > p {
  margin-top: 20px;
  font-family: "MuseoSans";
}
.mobilePage__recent {
  padding: 20px;
  margin: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
}
.mobilePage__recent > h4 {
  font-family: "MuseoSans";
}
.mobilePage__dis {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.recentImg {
  object-fit: contain;
  height: 50px;
}
.mobilePage__recentLink > p {
  margin-top: 4px;
  font-family: "MuseoSans";
}
.mobilePage__recentPara {
  color: gray;
}
.mobilepage__provider {
  padding: 20px;
  margin: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
}
.mobilepage__provider > h4 {
  font-family: "MuseoSans";
}
.mobilepage__pros {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important  ;
  margin-left: 0px;
  margin-top: 10px;
}
.mobilepage__prosLoading {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important  ;
  margin-top: 10px;
}
.moviLoading {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mobilePage__recentImg {
  cursor: pointer;
}
.Mobilepage__loading {
  background-color: white;
  width: 100vw;
  height: 100vh;
  place-items: center;
  display: grid;
}
.loadingImg {
  height: 100px !important;
}
@media (min-width: 411px) {
  .mobilepage__pros {
    margin-left: 10px !important;
  }
}
