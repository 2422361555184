.homepage {
  overflow: scroll;
  background-color: #eeeeee;
  overflow-x: hidden !important;
}
.homepage::-webkit-scrollbar {
  display: none !important;
}
.homepage__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
}

.homepageIcon {
  font-size: 100px !important;
  margin-right: 30px;
}
.homepage__title > p {
  margin-top: 20px;
  font-family: "MuseoSans";
}
.homepage__title > h2 {
  font-family: "MuseoSans";
}
.homepageLoading__service {
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
  margin: 20px;
}
.homepageLoading__service > h4 {
  font-family: "MuseoSans";
  padding: 20px;
}
.homepage__service {
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
  margin: 20px;
}
.homepage__service > h4 {
  font-family: "MuseoSans";
  padding: 20px;
}

.homepageService {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.homepageServiceLoading {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.loadingIcon {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}
.homepage__links {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  margin: 20px;
}
.homepage__links > h4 {
  font-family: "MuseoSans";
  padding: 20px;
}
.homepage__quickLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}
.homepage__quickLinks > .Quick__three {
  margin-left: 200px !important;
}
.homepage__orders {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.homepage__orders > h4 {
  font-family: "MuseoSans";
}
.homepage_orderIcon {
  color: #004fac !important;
  font-size: 16px !important;
}
.loadingIcon > .MuiSkeleton-circle {
  width: 25%;
}
.home_pageLoading {
  background-color: white;
  height: 100vh;
  place-items: center;
  display: grid;
}
.loadingImg {
  height: 100px !important;
}
@media (max-width: 290px) {
  .homepage__links {
    padding: 5px !important;
  }
  .homepage__service {
    padding: 5px !important;
    margin-top: 10px !important;
  }
}
@media (max-width: 320px) {
  .homepage__service {
    padding: 10px !important;
    margin-top: 10px !important;
  }
  .homepageService {
    margin-left: 3px !important;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 450px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 21px !important;
    margin-right: 21px !important;
  }
}
@media (max-width: 360px) {
  .loadingIcon {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
}

@media (max-width: 375px) {
  .loadingIcon {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
}
@media only screen and (min-device-width: 450px) and (max-device-width: 460px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

@media only screen and (min-device-width: 461px) and (max-device-width: 540px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}
@media only screen and (min-device-width: 540px) and (max-device-width: 640px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}
@media only screen and (min-device-width: 640px) and (max-device-width: 740px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}
@media only screen and (min-device-width: 740px) and (max-device-width: 760px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
}
@media only screen and (min-device-width: 760px) and (max-device-width: 1000px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 1080px) {
  .homepageService {
    margin-left: 10px !important;
  }
  .loadingIcon {
    margin-top: 17px !important;
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
}
