.ooredoo {
  overflow: scroll;
  background-color: #eeeeee !important;
}
.ooredoo::-webkit-scrollbar {
  display: none;
}
.ooredo__number::-webkit-scrollbar {
  display: none;
}
.ooredoo__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
}
.ooredoo__header > h2 {
  font-family: "MuseoSans";
}
.ooredoo__header > p {
  margin-top: 20px;
  font-family: "MuseoSans";
}
.ooredo__number {
  background-color: white;
  padding: 20px;
  overflow-x: scroll;
}
.ooredoo__paid {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ooredo__number > form {
  margin-top: 20px;
}
.ooredoo__input {
  margin-top: 20px;
  outline: none;
  border: none;
  border-bottom: 1px solid #004fac;
  width: fit-content;
  padding-bottom: 5px !important;
}
.ooredoo__input > input {
  outline: none;
  border: none;
}

.ooredo__number > p {
  color: gray;
  margin-top: 20px;
  font-weight: 400 !important;
  font-family: "MuseoSans";
}
.ooredoo__button {
  display: flex;
  align-items: center;
  overflow-x: scroll !important;
}

.ooredoo__buttonARA {
  display: inline-block;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.ooredoo__button::-webkit-scrollbar {
  display: none;
}
.ooredoo__buttonARA::-webkit-scrollbar {
  display: none;
}
.oored00__input {
  border-radius: 20px !important;
  margin-top: 20px !important;
  text-transform: inherit !important;
  font-family: "MuseoSans" !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  min-width: auto !important;
}
.oored00__input > .MuiButton-root {
  font-size: 14px !important;
  min-width: auto !important;
}
.oored00__inputARA {
  border-radius: 20px !important;
  margin-top: 10px !important;
  text-transform: inherit !important;
  font-family: "MuseoSans" !important;
  margin-left: 2px !important;
  margin-right: 0px !important;
  min-width: auto !important;
}
.oored00__inputARA > .MuiButton-root {
  font-size: 14px !important;

  min-width: auto !important;
}
.ooredoo__validity {
  margin-top: 20px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px !important;
}
.ooredoo__data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ooredoo__data > h2 {
  font-size: 20px !important;
  padding-right: 7px;

  font-family: "MuseoSans" !important;
}
.ooredoo__da {
  padding-left: 8px;
}
.ooredoo__da > p {
  color: gray;
  margin-top: 5px;
  font-size: 14px;
  font-family: "MuseoSans";
}
.ooreddoo__para {
  color: #363a4b !important;
}

.oredo_voiice {
  color: gray;
  margin-top: 20px;
}
.oredo_voiice > h5 {
  font-family: "MuseoSans";
}
.ooredoo__arro {
  margin-left: 15px;
}

.ooredoo__validityTwo {
  margin-top: 20px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px !important;
}
.ooredoo__plans {
  margin-top: 20px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px !important;
}
@media (max-width: 330px) {
  .ooredoo__validity {
    padding: 10px !important;
    margin: 10px !important;
  }
  .ooredoo__validityTwo {
    padding: 10px !important;
    margin: 10px !important;
    margin-bottom: 50px !important;
  }
  .ooredoo__data > h2 {
    font-size: 16px !important;
  }
  .ooredoo__da > p {
    font-size: 12px !important;
  }
}
