body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.index_loading {
  background-color: white;
  width: 100vw;
  height: 100vh;
  place-items: center;
  display: grid;
}
.loadingImg {
  height: 150px !important;
}

/* Custom Fonts */

@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"), url("./fonts/museosans-300.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"), url("./fonts/museosans-500.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"), url("./fonts/museosans-700.ttf") format("truetype");
  font-weight: 700;
}
