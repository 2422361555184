.homepage__Service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin-bottom: 15px;
}
a {
  text-decoration: none !important;
}
.service__icon {
  color: #2c7ad5;
}
.homepage__Service_Inner > h5 {
  color: gray;
  cursor: pointer;
  font-family: "MuseoSans";
  font-weight: 700 !important;
  text-align: center;
}
.homepage__Service_Inner > button {
  width: 100%;
}
@media (max-width: 500px) {
  .homepage__Service_Inner > h5 {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
}
@media (min-width: 411px) {
  .homepage__Service {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 414px) {
  .homepage__Service {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 320px) {
  .homepage__Service {
    margin-left: 2px;
    margin-right: 2px;
  }
}
