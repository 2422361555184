.success {
  background-color: white;
  height: 100vh;
}
.success__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  height: 45px;
}
.success__header > h2 {
  font-family: "MuseoSans";
}
.success__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
}
.success__body > .MuiSvgIcon-root {
  color: #4ec1a9;
  font-size: 70px !important;
  margin-bottom: 20px;
}
.success__body > p {
  font-family: "MuseoSans";
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
}
.success__body > img {
  object-fit: contain;
  height: 48px;
  margin-top: 20px;
}
.success__body > Button {
  text-transform: inherit !important;
  font-family: "MuseoSans";
  width: 60%;
  margin-top: 20px;
  text-transform: inherit !important;
}

.success__loaderHeader {
  background-color: #004fac;
  color: white;
  padding: 20px;
}
.success__loaderHeader > h2 {
  font-family: "MuseoSans";
}
.success__loaderbody {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
}
.success__loaderbody > img {
  margin-bottom: 20px;
}
.success__loaderbody > p {
  font-family: "MuseoSans";
  margin-top: 10px;
}
.loadingImg {
  height: 100px !important;
}
.inactive {
  display: none;
}
