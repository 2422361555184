.summary {
  overflow-x: hidden !important;
  background-color: white;
  height: 100vh;
  overflow-y: scroll;
}
.summary::-webkit-scrollbar {
  display: none;
}
.summary__dis {
  background-color: white;
  padding-bottom: 20px !important;
}
.summary__header {
  background-color: #004fac;
  color: white;
  padding: 20px;
  height: 45px;
}
.summary__header > h2 {
  font-family: "MuseoSans";
}
.summary__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}
.summary__body > p {
  font-family: "MuseoSans";
}
.summary__body > img {
  object-fit: contain;
  height: 40px;
  margin-left: 10px;
}
.summary__number {
  padding-bottom: 4px;
  border-bottom: 1px solid #004fac;
  margin-right: 20px;
}
.summary__validity {
  margin-top: 20px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.summary__dataa > h2 {
  font-family: "MuseoSans";
  margin-right: 40px;
}
.summary__Ardata > h2 {
  font-family: "MuseoSans";
  margin-left: 40px;
}
.summary__Ardata {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary__Ardata > Button {
  border-radius: 20px;
  font-family: "MuseoSans";
  text-transform: inherit !important;
}
.summary__Ardata > .MuiButton-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.summary__dataa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary__dataa > Button {
  border-radius: 20px;
  font-family: "MuseoSans";
  text-transform: inherit !important;
}
.summary__dataa > .MuiButton-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ooredoo__da > p {
  color: gray;
  margin-top: 5px;
  font-family: "MuseoSans";
}
.ooreddoo__para {
  color: #363a4b !important;
}
.oredo_voice {
  color: gray;
  margin-top: 20px;
}
.oredo_voice > h5 {
  font-family: "MuseoSans";
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 7;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ooredoo__arro {
  margin-left: 15px;
}
.summary__pay {
  margin: 5px 0;
  border-top: 2px solid #eeeeee;
}
.summary__card {
  background-color: white;
}
.summary__proceed {
  position: relative;
  width: 100% !important;
  background-color: white;
}
.summary__proceed > Button {
  position: fixed;
  z-index: 0;
  bottom: 0;
  width: 100% !important;
  font-family: "MuseoSans";
}
.summary__proceed > .MuiButton-root {
  font-size: 16px !important;
  border-radius: 0 !important;
}
.summaryPayment {
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}
.summaryPayment > h5 {
  font-family: "MuseoSans";
  flex: 1;
  margin-left: 20px;
}
.summaryPayment__img {
  object-fit: contain;
}
.summaryPaymentDebit {
  object-fit: contain;
  height: 38px;
}
.summaary___input > input[type="checkbox" i] {
  border-radius: 100% !important;
  border: solid !important;
}
.summaary___input > .MuiCheckbox-root.Mui-checked {
  color: #004fac !important;
  border-radius: 100%;
}
.summaary___input > .MuiIconButton-root {
  border-radius: 100% !important;
}
.summary__paraHed {
  margin-top: 10px;
}
.summary__paraHed > p {
  font-size: 12px;
  color: gray;
  margin-left: 10px;
  margin-right: 8px;
  font-family: "MuseoSans";
}
.summary__paraHed > h5 {
  font-family: "MuseoSans";
  flex: 1;
  margin-left: 10px;
}
.summaryArPara {
  margin-top: 10px;
}
.summaryArPara > p {
  font-size: 12px;
  color: gray;
  margin-left: 8px;
  margin-right: 10px;
  font-family: "MuseoSans";
}
.summaryArPara > h5 {
  font-family: "MuseoSans";
  flex: 1;
  margin-right: 10px;
}
.summary_loading {
  background-color: white;
  width: 100vw;
  height: 100vh;
  place-items: center;
  display: grid;
}
.loadingImg {
  height: 100px !important;
}
@media (max-width: 320px) {
  .summary {
    height: 120vh;
  }
}
