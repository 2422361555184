.notFound{
    background-color: white;
    height: 100vh;
   display: grid;
   place-items: center;
   text-align: center;
}
.notFound>h5{
    margin-top: 10px;
    margin-bottom: 10px;
}