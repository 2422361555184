.NetworkError{
    background-color: white;
    height: 100vh;
   display: grid;
   place-items: center;
   text-align: center;
}
.NetworkError>h4{
    margin-top: 10px;
    margin-bottom: 10px;
}
.linkToHome{
    margin-top: 15px;
}